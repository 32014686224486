<template>
  <label :for="elementId" :class="[$style.VCheckbox, mods]">
    <input
      v-bind="{ ...$attrs }"
      :id="elementId"
      :checked="modelValue"
      :class="$style.native"
      type="checkbox"
      @change="
        $emit('update:modelValue', ($event.target as HTMLInputElement).checked)
      "
    />
    <span :class="$style.body">
      <SvgoIconCheck :class="$style.arrow" />
    </span>
    <slot>{{ props.label }}</slot>
  </label>
</template>

<script lang="ts" setup>
export interface Props {
  modelValue?: boolean;
  checkedValue?: boolean;
  label?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  checkedValue: true,
  label: "",
  disabled: false,
});

defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const elementId = `v-checkbox-${Math.random().toString(36).slice(2, 7)}`;

const style = useCssModule();
const mods = computed(() => ({
  [style.checked]: props.modelValue,
  [style.disabled]: props.disabled,
}));
</script>

<style lang="scss" module>
.VCheckbox {
  display: flex;
  align-items: center;
  outline: none;
  user-select: none;
  cursor: pointer;
  color: var(--text-main);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 130%;

  @include respond-to(xs) {
    font-size: 12px;
  }
}

.body {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;
  border: 2px solid var(--text-color-2);
  border-radius: 4px;
  background-color: var(--main-block-bg);
  transition:
    background-color $base-transition,
    border-color $base-transition;

  &:hover {
    border: 2px solid #689de4;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  transform: scale(0);
}

.native {
  display: none;
}

.disabled {
  pointer-events: none;
}

.checked {
  & .body {
    background-color: $brand-blue;
    border: 2px solid $brand-blue;
  }

  & .arrow {
    transform: scale(1) translate(-50%, -50%);
  }
}
</style>
